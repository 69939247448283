export const columnsTable = {
  admin: [
    {
      Header: "№",
      accessor: "num",
    },
    {
      Header: "delete",
      accessor: "delete",
    },
    {
      Header: "Date",
      accessor: "date",
    },
    {
      Header: "Name",
      accessor: "name",
    },
    {
      Header: "Agency",
      accessor: "agencyTitle",
    },
    {
      Header: "Customs",
      accessor: "customs",
    },
    {
      Header: "put",
      accessor: "put",
    },
    {
      Header: "details",
      accessor: "details",
    },
  ],
  user: [
    {
      Header: "№",
      accessor: "num",
    },
    {
      Header: "put",
      accessor: "put",
    },
    {
      Header: "Date",
      accessor: "date",
    },
    {
      Header: "Name",
      accessor: "name",
    },
    {
      Header: "Agency",
      accessor: "agencyTitle",
    },
    {
      Header: "Customs",
      accessor: "customs",
    },

    {
      Header: "details",
      accessor: "details",
    },
  ],
};
