import React from "react";
import TableReport from "./components/table/Table";

const Order = () => {
  return (
    <>
      <TableReport />
    </>
  );
};

export default Order;
